// ChatBox.js
import React, { useState, useEffect } from 'react';
import { ref, onValue, push, update } from 'firebase/database';
import { auth, database } from '../../firebaseConfig';

const ChatBox = ({ patientId }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  useEffect(() => {
    const chatRef = ref(database, `chats/${patientId}`);
    const unsubscribe = onValue(chatRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const messagesArray = Object.values(data);
        setMessages(messagesArray);
      }
    });

    return () => unsubscribe();
  }, [patientId]);

  const handleSendMessage = async () => {
    const chatRef = ref(database, `chats/${patientId}`);
    const messageData = {
      senderId: auth.currentUser?.uid,
      text: newMessage,
      timestamp: Date.now(),
    };
    await push(chatRef, messageData);
    setNewMessage("");
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <div className="max-h-64 overflow-y-auto mb-4">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`mb-2 ${
              msg.senderId === auth.currentUser?.uid
                ? 'text-right'
                : 'text-left'
            }`}
          >
            <p className="text-sm">{msg.text}</p>
          </div>
        ))}
      </div>
      <input
        type="text"
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        placeholder="Scrivi un messaggio..."
        className="border rounded-md w-full p-2 mb-2"
      />
      <button
        onClick={handleSendMessage}
        className="bg-blue-500 text-white rounded-md px-4 py-2 w-full hover:bg-blue-600"
      >
        Invia
      </button>
    </div>
  );
};

export default ChatBox;