import React, { useState, useEffect } from 'react';
import { ref, onValue, update } from 'firebase/database';
import { auth, database } from '../../firebaseConfig';
import { FileText, CreditCard } from 'lucide-react';
import Sidebar from './Sidebar';

const TherapistInvoicesPage = () => {
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const invoicesRef = ref(database, 'invoices');
        onValue(invoicesRef, (snapshot) => {
          const data = snapshot.val();
          const userInvoices = [];

          if (data) {
            // Itera sui pazienti
            Object.keys(data).forEach((patientId) => {
              // Itera sulle fatture di ogni paziente
              const patientInvoices = data[patientId];
              Object.keys(patientInvoices).forEach((invoiceId) => {
                const invoice = patientInvoices[invoiceId];
                if (invoice.therapistId === user.uid) {
                  userInvoices.push({ ...invoice, id: invoiceId, patientId });
                }
              });
            });
          }

          setInvoices(userInvoices);
        });
      } else {
        setInvoices([]);
      }
    });

    return unsubscribe;
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return "Data non disponibile";
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "Data non valida";
    return new Intl.DateTimeFormat('it-IT').format(date);
  };

  const formatAmount = (amount) => {
    return new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR'
    }).format(amount);
  };

  const payInvoice = async (invoiceId, patientId) => {
    try {
      const invoiceRef = ref(database, `invoices/${patientId}/${invoiceId}`);
      await update(invoiceRef, { status: 'paid' });
      alert("Fattura pagata con successo!");
    } catch (error) {
      console.error("Errore durante il pagamento della fattura:", error);
      alert("Si è verificato un errore durante il pagamento della fattura.");
    }
  };

  return (
    <div className="flex min-h-screen bg-white">
     <div className="w-[280px] fixed">
        <Sidebar />
      </div>
      <div className="flex-1 p-8 bg-gray-50 ml-52">
        <div className="max-w-4xl mx-auto">
          <header className="flex justify-between items-center mb-8">
            <h1 className="text-2xl font-serif text-gray-800">Le Tue Fatture</h1>
          </header>

          <div className="bg-white rounded-lg shadow-sm overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Numero</th>
                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Data</th>
                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Importo</th>
                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Stato</th>
                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Azioni</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {invoices.length > 0 ? (
                    invoices.map((invoice, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-6 py-4 text-sm text-gray-900">{invoice.id}</td>
                        <td className="px-6 py-4 text-sm text-gray-600">{formatDate(invoice.date)}</td>
                        <td className="px-6 py-4 text-sm text-gray-900">{formatAmount(invoice.amount)}</td>
                        <td className="px-6 py-4 text-sm">
                          <span
                            className={`px-2 py-1 rounded ${invoice.status === 'paid'
                              ? 'bg-green-100 text-green-600'
                              : 'bg-yellow-100 text-yellow-600'
                            }`}
                          >
                            {invoice.status}
                          </span>
                        </td>
                        <td className="px-6 py-4 text-right">
                          {invoice.status === 'unpaid' && (
                            <button
                              onClick={() => payInvoice(invoice.id, invoice.patientId)}
                              className="text-coral-500 hover:text-coral-600 mr-4"
                            >
                              <CreditCard className="w-5 h-5" />
                            </button>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="px-6 py-8 text-center text-gray-500">
                        <FileText className="w-12 h-12 mx-auto mb-4 text-gray-400" />
                        Nessuna fattura disponibile al momento
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TherapistInvoicesPage;