import React, { useState } from "react";
import { ref, update } from "firebase/database";
import { auth, database } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { ChevronRight, ChevronLeft, Check, AlertCircle } from "lucide-react";

const PatientForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    therapyType: "",
    ageRange: "",
    reasons: [],
    specificIssues: [],
    preferredGender: "",
    preferredTime: "",
    therapistAgePreference: "",
    matchStatus: "pending",

  });
  const navigate = useNavigate();

  const steps = [
    { number: 1, title: "Tipo di Terapia" },
    { number: 2, title: "Informazioni Personali" },
    { number: 3, title: "Motivi" },
    { number: 4, title: "Specifiche" },
    { number: 5, title: "Preferenze" },
  ];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (errors[e.target.name]) {
      setErrors({ ...errors, [e.target.name]: "" });
    }
  };

  const handleCheckboxChange = (reason) => {
    const updatedReasons = formData.reasons.includes(reason)
      ? formData.reasons.filter((r) => r !== reason)
      : [...formData.reasons, reason];
    setFormData({ ...formData, reasons: updatedReasons });
    if (errors.reasons) {
      setErrors({ ...errors, reasons: "" });
    }
  };

  const handleSpecificIssueChange = (issue) => {
    const updatedSpecificIssues = formData.specificIssues.includes(issue)
      ? formData.specificIssues.filter((i) => i !== issue)
      : [...formData.specificIssues, issue];
    setFormData({ ...formData, specificIssues: updatedSpecificIssues });
    if (errors.specificIssues) {
      setErrors({ ...errors, specificIssues: "" });
    }
  };

  const validateStep = (step) => {
    const newErrors = {};

    switch (step) {
      case 1:
        if (!formData.therapyType) {
          newErrors.therapyType = "Seleziona un tipo di terapia";
        }
        break;
      case 2:
        if (!formData.ageRange) {
          newErrors.ageRange = "Seleziona la tua fascia d'età";
        }
        break;
      case 3:
        if (formData.reasons.length === 0) {
          newErrors.reasons = "Seleziona almeno un motivo";
        }
        break;
      case 4:
        // Validazione per problematiche specifiche basate sui motivi selezionati
        if (formData.reasons.includes("Sento di non avere una vita emotiva serena") && 
            !formData.specificIssues.some(issue => 
              ["Disturbi dell'umore", "Disturbi d'ansia", "Disturbi alimentari", "Disturbo ossessivo-compulsivo"]
              .includes(issue))) {
          newErrors.emotionalIssues = "Seleziona almeno un disturbo specifico";
        }
        if (formData.reasons.includes("Vivo difficoltà nei rapporti interpersonali") && 
            !formData.specificIssues.some(issue => 
              ["Difficoltà con il partner", "Difficoltà nelle amicizie", "Difficoltà nelle relazioni familiari", "Difficoltà nella sfera sessuale"]
              .includes(issue))) {
          newErrors.relationshipIssues = "Seleziona almeno una difficoltà specifica";
        }
        break;
      case 5:
        if (!formData.preferredGender) {
          newErrors.preferredGender = "Seleziona una preferenza di genere";
        }
        if (!formData.preferredTime) {
          newErrors.preferredTime = "Seleziona una fascia oraria preferita";
        }
        break;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentStep !== steps.length) return;
    
    const isValid = validateStep(currentStep);
    if (!isValid) return;

    const userId = auth.currentUser?.uid;
    if (userId) {
      try {
        await update(ref(database, `users/${userId}`), { 
          ...formData, 
          profileComplete: true,
          lastUpdated: new Date().toISOString()
        });
        navigate("/dashboard-patient");
      } catch (error) {
        setErrors({ submit: "Si è verificato un errore. Riprova più tardi." });
      }
    }
  };

  const nextStep = () => {
    const isValid = validateStep(currentStep);
    if (isValid) {
      setCurrentStep(prev => Math.min(prev + 1, steps.length));
    }
  };

  const prevStep = () => setCurrentStep(prev => Math.max(prev - 1, 1));

  const renderError = (error) => {
    if (!error) return null;
    return (
      <div className="flex items-center text-red-500 text-sm mt-2">
        <AlertCircle className="w-4 h-4 mr-1" />
        <span>{error}</span>
      </div>
    );
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="animate-slide-in">
            <h3 className="text-lg font-medium text-emerald-700 mb-4">Scegli il tipo di terapia</h3>
            <select 
              name="therapyType" 
              value={formData.therapyType} 
              onChange={handleChange}
              className={`w-full p-3 rounded-lg border ${errors.therapyType ? 'border-red-500' : 'border-gray-200'} focus:border-emerald-500 focus:ring-2 focus:ring-emerald-200 transition-all`}
            >
              <option value="">Seleziona il tipo di terapia</option>
              <option value="individual">Individuale</option>
              <option value="couple">Di coppia</option>
            </select>
            {renderError(errors.therapyType)}
          </div>
        );

      case 2:
        return (
          <div className="animate-slide-in">
            <h3 className="text-lg font-medium text-emerald-700 mb-4">La tua età</h3>
            <select 
              name="ageRange" 
              value={formData.ageRange} 
              onChange={handleChange}
              className={`w-full p-3 rounded-lg border ${errors.ageRange ? 'border-red-500' : 'border-gray-200'} focus:border-emerald-500 focus:ring-2 focus:ring-emerald-200 transition-all`}
            >
              <option value="">Seleziona la tua fascia d'età</option>
              <option value="18-25">18 - 25</option>
              <option value="26-39">26 - 39</option>
              <option value="40-50">40 - 50</option>
              <option value="over50">Più di 50</option>
            </select>
            {renderError(errors.ageRange)}
          </div>
        );

      case 3:
        return (
          <div className="animate-slide-in">
            <h3 className="text-lg font-medium text-emerald-700 mb-4">Motivi della richiesta</h3>
            <div className="space-y-3">
              {[
                "Sento di non avere una vita emotiva serena",
                "Vivo difficoltà nei rapporti interpersonali",
                "Sto avendo problemi nell'ambito lavorativo",
                "Provo difficoltà legate alla mia esperienza di vita all'estero"
              ].map((reason, index) => (
                <div 
                  key={index} 
                  className={`flex items-center p-3 rounded-lg border ${
                    errors.reasons ? 'border-red-500' : 'border-gray-200'
                  } hover:border-emerald-500 transition-all`}
                >
                  <input
                    type="checkbox"
                    checked={formData.reasons.includes(reason)}
                    onChange={() => handleCheckboxChange(reason)}
                    className="mr-3 h-4 w-4 text-emerald-600 focus:ring-emerald-500"
                  />
                  <label className="text-gray-700">{reason}</label>
                </div>
              ))}
            </div>
            {renderError(errors.reasons)}
          </div>
        );

      case 4:
        return (
          <div className="animate-slide-in">
            <h3 className="text-lg font-medium text-emerald-700 mb-4">Specifiche</h3>
            
            {formData.reasons.includes("Sento di non avere una vita emotiva serena") && (
              <div className="mb-6">
                <h4 className="text-sm font-medium text-gray-700 mb-3">Disturbi emotivi e dell'umore - Specifica:</h4>
                <div className="space-y-3">
                  {["Disturbi dell'umore", "Disturbi d'ansia", "Disturbi alimentari", "Disturbo ossessivo-compulsivo"].map((issue, index) => (
                    <div 
                      key={index}
                      className="flex items-center p-3 rounded-lg border border-gray-200 hover:border-emerald-500 transition-all"
                    >
                      <input
                        type="checkbox"
                        checked={formData.specificIssues.includes(issue)}
                        onChange={() => handleSpecificIssueChange(issue)}
                        className="mr-3 h-4 w-4 text-emerald-600 focus:ring-emerald-500"
                      />
                      <label className="text-gray-700">{issue}</label>
                    </div>
                  ))}
                </div>
                {renderError(errors.emotionalIssues)}
              </div>
            )}

            {formData.reasons.includes("Vivo difficoltà nei rapporti interpersonali") && (
              <div className="mb-6">
                <h4 className="text-sm font-medium text-gray-700 mb-3">Difficoltà relazionali - Specifica:</h4>
                <div className="space-y-3">
                  {["Difficoltà con il partner", "Difficoltà nelle amicizie", "Difficoltà nelle relazioni familiari", "Difficoltà nella sfera sessuale"].map((issue, index) => (
                    <div 
                      key={index}
                      className="flex items-center p-3 rounded-lg border border-gray-200 hover:border-emerald-500 transition-all"
                    >
                      <input
                        type="checkbox"
                        checked={formData.specificIssues.includes(issue)}
                        onChange={() => handleSpecificIssueChange(issue)}
                        className="mr-3 h-4 w-4 text-emerald-600 focus:ring-emerald-500"
                      />
                      <label className="text-gray-700">{issue}</label>
                    </div>
                  ))}
                </div>
                {renderError(errors.relationshipIssues)}
              </div>
            )}
          </div>
        );

      case 5:
        return (
          <div className="animate-slide-in">
            <h3 className="text-lg font-medium text-emerald-700 mb-4">Le tue preferenze</h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Preferisci incontrare un terapeuta:
                </label>
                <select 
                  name="preferredGender" 
                  value={formData.preferredGender} 
                  onChange={handleChange}
                  className={`w-full p-3 rounded-lg border ${errors.preferredGender ? 'border-red-500' : 'border-gray-200'} focus:border-emerald-500 focus:ring-2 focus:ring-emerald-200 transition-all`}
                >
                  <option value="">Seleziona una preferenza</option>
                  <option value="no preference">Nessuna preferenza</option>
                  <option value="female">Donna</option>
                  <option value="male">Uomo</option>
                </select>
                {renderError(errors.preferredGender)}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Età del terapeuta:
                </label>
                <select 
                  name="therapistAgePreference" 
                  value={formData.therapistAgePreference} 
                  onChange={handleChange}
                  className={`w-full p-3 rounded-lg border ${errors.therapistAgePreference ? 'border-red-500' : 'border-gray-200'} focus:border-emerald-500 focus:ring-2 focus:ring-emerald-200 transition-all`}
                >
                  <option value="">Seleziona una preferenza</option>
                  <option value="no preference">Nessuna preferenza</option>
                  <option value="under35">Fino a 35 anni</option>
                  <option value="over35">Oltre i 35 anni</option>
                </select>
                {renderError(errors.therapistAgePreference)}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Fascia oraria preferita:
                </label>
                <select 
                  name="preferredTime" 
                  value={formData.preferredTime} 
                  onChange={handleChange}
                  className={`w-full p-3 rounded-lg border ${errors.preferredTime ? 'border-red-500' : 'border-gray-200'} focus:border-emerald-500 focus:ring-2 focus:ring-emerald-200 transition-all`}
                >
                  <option value="">Seleziona una fascia oraria</option>
                  <option value="no preference">Nessuna preferenza</option>
                  <option value="morning">Mattina</option>
                  <option value="afternoon">Pomeriggio</option>
                  <option value="evening">Sera</option>
                </select>
                {renderError(errors.preferredTime)}
              </div>
            </div>
            {renderError(errors.submit)}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-6">
       <img 
          src="/logo.png" 
          alt="Etherapy" 
          className="h-[100px] object-contain transition-all duration-200 hover:opacity-90 flex flex-col items-center w-full mb-10" 
        />
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-emerald-700 mb-6 text-center">Completa il tuo profilo</h2>
        
        {/* Progress bar */}
        <div className="flex justify-between mb-8">
          {steps.map((step) => (
            <div key={step.number} className="flex flex-col items-center w-full">
              <div className={`
                w-8 h-8 rounded-full flex items-center justify-center mb-2
                ${currentStep > step.number 
                  ? 'bg-emerald-500 text-white' 
                  : currentStep === step.number 
                    ? 'bg-gradient-to-r from-emerald-500 to-teal-500 text-white'
                    : 'bg-gray-200 text-gray-500'
                }
              `}>
                {currentStep > step.number ? <Check className="w-4 h-4" /> : step.number}
              </div>
              <div className="text-xs text-center text-gray-600">{step.title}</div>
              {step.number < steps.length && (
                <div className={`h-1 w-full   ${
                  currentStep > step.number ? 'bg-emerald-500' : ''
                }`} />
              )}
            </div>
          ))}
        </div>
      </div>

      <form onSubmit={handleSubmit} className="bg-white rounded-xl p-6 shadow-sm border border-gray-100">
        {renderStep()}

        <div className="flex justify-between mt-8">
          {currentStep > 1 && (
            <button
              type="button"
              onClick={prevStep}
              className="flex items-center px-4 py-2 text-gray-600 hover:text-emerald-600 transition-colors"
            >
              <ChevronLeft className="w-4 h-4 mr-2" />
              Indietro
            </button>
          )}
          
          {currentStep < steps.length ? (
            <button
              type="button"
              onClick={nextStep}
              className="ml-auto flex items-center px-6 py-2 bg-gradient-to-r from-emerald-500 to-teal-500 text-white rounded-lg hover:from-emerald-600 hover:to-teal-600 transition-all"
            >
              Avanti
              <ChevronRight className="w-4 h-4 ml-2" />
            </button>
          ) : (
            <button
              type="submit"
              className="ml-auto flex items-center px-6 py-2 bg-gradient-to-r from-emerald-500 to-teal-500 text-white rounded-lg hover:from-emerald-600 hover:to-teal-600 transition-all"
            >
              Completa
              <Check className="w-4 h-4 ml-2" />
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default PatientForm;