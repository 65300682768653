import React, { useState, useEffect, useRef } from 'react';
import { ref, onValue, push, set } from 'firebase/database';
import { auth, database } from '../../firebaseConfig';
import { MessageCircle, User, Send, LockKeyhole } from 'lucide-react';
import Sidebar from './Sidebar';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { Calendar as CalendarComponent } from '../ui/calendar';
import ChatbotComponent from './ChatbotComponent';


const ChatMessage = ({ message, isOwnMessage, isTherapistMessage }) => (
  <div className={`flex ${isOwnMessage ? 'justify-end' : 'justify-start'} mb-4`}>
    <div
      className={`max-w-[70%] px-4 py-2 rounded-lg backdrop-blur-sm ${isOwnMessage
        ? 'bg-white/90 text-right text-gray-800'
        : isTherapistMessage
          ? 'bg-gradient-to-r from-emerald-600 to-teal-600/90 text-white'
          : 'bg-white/90 text-gray-800'
        }`}
    >
      <p className="text-sm">{message.text}</p>
      <span className="text-xs mt-1 block opacity-70">
        {new Date(message.timestamp).toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' })}
      </span>
    </div>
  </div>
);

const TimeSlotPicker = ({ availableSlots, onSelectTime }) => (
  <div className="grid grid-cols-2 gap-2 mt-4">
    {availableSlots.map((slot) => (
      <Button
        key={slot}
        variant="outline"
        onClick={() => onSelectTime(slot)}
        className="text-sm py-2 bg-white/80 hover:bg-emerald-50 border-emerald-200 text-emerald-700"
      >
        {slot}
      </Button>
    ))}
  </div>
);



const PatientDashboard = () => {
  const [profile, setProfile] = useState(null);
  const [therapist, setTherapist] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [bookingError, setBookingError] = useState(null);
  const [isFirstInvoicePaid, setIsFirstInvoicePaid] = useState(); // Per test temporaneo
  const chatEndRef = useRef(null);
  const [isFirstAppointment, setIsFirstAppointment] = useState(true);
  const [showChat, setShowChat] = useState(false);

  const availableTimeSlots = ["09:00", "10:00", "11:00", "14:00", "15:00", "16:00"];

  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if (!userId) return;

    // Check invoice status
    const invoicesRef = ref(database, `invoices/${userId}`);
    onValue(invoicesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const invoices = Object.values(data);
        // Sort invoices by date and check if the first one is paid
        const sortedInvoices = invoices.sort((a, b) =>
          new Date(a.date).getTime() - new Date(b.date).getTime()
        );
        setIsFirstInvoicePaid(sortedInvoices[0]?.status === 'paid');
      }
    });

    const userRef = ref(database, `users/${userId}`);
    onValue(userRef, (snapshot) => {
      const userData = snapshot.val();
      if (userData) {
        setProfile({ id: userId, ...userData });

        if (userData.matchStatus === 'matched' && userData.matchedTherapistId) {
          const therapistRef = ref(database, `users/${userData.matchedTherapistId}`);
          onValue(therapistRef, (therapistSnapshot) => {
            const therapistData = therapistSnapshot.val();
            if (therapistData) {
              setTherapist({ id: userData.matchedTherapistId, ...therapistData });
            }
          });
        }
      }
    });

    const appointmentsRef = ref(database, `appointments/${userId}`);
    onValue(appointmentsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const appointments = Object.values(data);
        setAppointments(appointments);
        setIsFirstAppointment(appointments.length === 0);
      }
    });
  }, []);

  useEffect(() => {
    if (profile?.matchStatus === 'matched' && therapist && isFirstInvoicePaid) {
      const messagesRef = ref(database, `chats/${profile.id}`);

      onValue(messagesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setMessages(Object.values(data).sort((a, b) => a.timestamp - b.timestamp));
        }
      });
    }
  }, [profile, therapist, isFirstInvoicePaid]);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() || !therapist || !isFirstInvoicePaid) return;

    const chatId = `${profile.id}`;
    const messagesRef = ref(database, `chats/${chatId}`);
    const newMessageRef = push(messagesRef);

    const messageData = {
      text: newMessage,
      senderId: profile.id,
      senderType: 'patient',
      timestamp: Date.now(),
    };

    await set(newMessageRef, messageData);
    setNewMessage('');
  };

  const bookAppointment = async (timeSlot) => {
    if (!selectedDate) return setBookingError("Seleziona una data");
    if (!timeSlot) return setBookingError("Seleziona un orario");

    try {
      const appointmentData = {
        date: selectedDate.toISOString(),
        time: timeSlot,
        therapistId: therapist.id,
        therapistName: therapist.name,
        therapistSurname: therapist.surname,
        patientId: profile.id,
        name: profile.name,
        surname: profile.surname,
        status: 'scheduled',
        createdAt: new Date().toISOString()
      };

      const appointmentRef = ref(database, `appointments/${profile.id}`);
      const newAppointmentRef = push(appointmentRef);
      await set(newAppointmentRef, appointmentData);

      // Save invoice if it's not the first appointment
      if (!isFirstAppointment) {
        const invoiceData = {
          date: selectedDate.toISOString(),
          amount: 45,
          status: 'unpaid',
          therapistId: therapist.id,
          name: profile.name,
          surname: profile.surname,
          therapistName: therapist.name,
          therapistSurname: therapist.surname,

        };
        const invoiceRef = ref(database, `invoices/${profile.id}`);
        await set(push(invoiceRef), invoiceData);
      }

      setSelectedDate(null);
      setIsFirstAppointment(false);
      alert("Appuntamento prenotato con successo!");
    } catch (error) {
      setBookingError("Errore nella prenotazione");
    }
  };

  const renderChat = () => {
    if (!isFirstInvoicePaid) {
      return (
        <div className="h-[400px] overflow-y-auto p-4">
          <div className="h-full flex flex-col items-center justify-center text-gray-500">
            <LockKeyhole className="w-12 h-12 mb-4 text-emerald-400" />
            <h3 className="text-lg font-medium text-black mb-2">Chat bloccata</h3>
            <p className="text-center max-w-md">
              La chat sarà disponibile dopo il pagamento della prima fattura.
              Controlla la sezione fatture per procedere con il pagamento.
            </p>
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="h-[400px] overflow-y-auto p-4 space-y-4">
          {messages.map((msg, i) => (
            <ChatMessage
              key={i}
              message={msg}
              isOwnMessage={msg.senderId === profile?.id}
              isTherapistMessage={msg.senderType === 'therapist'}
            />
          ))}
          <div ref={chatEndRef} />
        </div>

        <div className="p-4 border-t">
          <form onSubmit={sendMessage} className="flex gap-2">
            <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Scrivi un messaggio..."
              className="flex-1 px-4 py-2 rounded-lg bg-white border-0 focus:ring-2 focus:ring-emerald-500 placeholder-gray-500"
            />
            <Button type="submit" className="bg-emerald-600 hover:bg-emerald-700">
              <Send className="w-5 h-5" />
            </Button>
          </form>
        </div>
      </>
    );
  };

  const renderContent = () => {
    switch (profile?.matchStatus) {
      case 'pending':
        return (
          <Card>
            <CardHeader><CardTitle>In attesa di abbinamento</CardTitle></CardHeader>
            <CardContent>La tua richiesta è in fase di elaborazione.</CardContent>
          </Card>
        );

      case 'rejected':
        return (
          <Card>
            <CardHeader><CardTitle>Richiesta non accettata</CardTitle></CardHeader>
            <CardContent>
              La tua richiesta non è stata accettata. Riprova o modifica le preferenze.
              <Button className="mt-4" onClick={() => window.location.href = "/patient-form"}>Modifica preferenze</Button>
            </CardContent>
          </Card>
        );

      case 'matched':
        return (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              <Card>
                <CardHeader><CardTitle>Il tuo terapeuta</CardTitle></CardHeader>
                <CardContent>
                  <div className="flex items-start gap-6">
                    <div className="w-24 h-24 bg-gray-100 rounded-full flex items-center justify-center"><User className="w-12 h-12 text-gray-400" /></div>
                    <div className="flex-1">
                      <p><span className="font-medium">Nome:</span> {therapist?.name}</p>
                      <p><span className="font-medium">Cognome:</span> {therapist?.surname}</p>
                    </div>
                  </div>
                </CardContent>
                <CardHeader className="-mt-4" ><CardTitle>Prenota una seduta</CardTitle></CardHeader>
                <CardContent>
                  <CalendarComponent mode="single" selected={selectedDate} onSelect={setSelectedDate} disabled={{ before: new Date(), after: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000) }} className="rounded-md border" />
                  {selectedDate && <TimeSlotPicker availableSlots={availableTimeSlots} onSelectTime={bookAppointment} />}
                  {bookingError && <p className="text-red-500 text-sm mt-2">{bookingError}</p>}
                </CardContent>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle>Appuntamenti Prenotati</CardTitle>
                </CardHeader>
                <CardContent>
                  {appointments.length > 0 ? (
                    <ul className="bg-gray-50 ">
                      {appointments.map((appointment, index) => (
                        <li key={index} className="border-b border-gray-200 py-4 flex justify-between items-center">
                          <div className="flex items-center gap-4">
                            <p className="text-gray-600 text-sm">

                              {new Date(appointment.date).toLocaleDateString('it-IT')}
                            </p>
                            <p className="text-gray-800 text-base font-medium">
                              {appointment.time}
                            </p>
                          </div>
                          <span
                            className={`text-sm px-2 py-1 rounded ${appointment.status === 'scheduled'
                              ? 'bg-cyan-100 text-cyan-600'
                              : 'bg-red-100 text-red-600'
                              }`}
                          >
                            {appointment.status}
                          </span>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>Non hai appuntamenti prenotati.</p>
                  )}
                </CardContent>
              </Card>
            </div>

            <Card className="border-0 bg-white/90 mt-6">
              <CardHeader className="border-b bg-gradient-to-r from-emerald-400/80 to-teal-400/80 text-white rounded-t-lg">
                <CardTitle className="text-lg font-medium flex items-center gap-2">
                  <MessageCircle className="w-5 h-5" />
                  Chat con {therapist?.name} {therapist?.surname}
                </CardTitle>
              </CardHeader>
              <CardContent className="p-0">
                {renderChat()}
              </CardContent>
            </Card>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div className="flex min-h-screen w-full bg-gray-50">
      <div className="w-[280px] fixed">
        <Sidebar />
      </div>

      <div className="flex-1 ml-[280px] p-8">
        <div className="max-w-5xl mx-auto">
          <header className="flex justify-between items-center mb-8">
            <h1 className="text-3xl font-serif text-emerald-800 font-semibold">Il Tuo Percorso</h1>
            <ChatbotComponent />
          </header>

          {profile?.matchStatus === 'matched' && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              <Card className="border-0 bg-white/90">
                <CardHeader className="border-b bg-gradient-to-r from-emerald-400/80 to-teal-400/80 text-white rounded-t-lg mb">
                  <CardTitle className="text-lg font-medium flex items-center gap-2">
                    Il tuo terapeuta
                  </CardTitle>
                </CardHeader>
                <CardContent className="p-6 mt-5">
                  <div className="flex items-start gap-6">
                    <div className="w-24 h-24 bg-gradient-to-br from-emerald-100 to-teal-50 rounded-full flex items-center justify-center">
                      <User className="w-12 h-12 text-emerald-600" />
                    </div>
                    <div className="flex-1 mt-4">
                      <p className="text-emerald-800"><span className="font-medium">Nome:</span> {therapist?.name}</p>
                      <p className="text-emerald-800"><span className="font-medium">Cognome:</span> {therapist?.surname}</p>
                    </div>
                  </div>

                  <div className="mt-6">
                    <h3 className="text-lg font-medium text-emerald-800 mb-4">Prenota una seduta</h3>
                    <CalendarComponent
                      mode="single"
                      selected={selectedDate}
                      onSelect={setSelectedDate}
                      disabled={{ before: new Date(), after: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000) }}
                      className="rounded-lg border border-emerald-100 bg-white"
                    />
                    {selectedDate && <TimeSlotPicker availableSlots={availableTimeSlots} onSelectTime={bookAppointment} />}
                    {bookingError && <p className="text-red-500 text-sm mt-2">{bookingError}</p>}
                  </div>
                </CardContent>
              </Card>

              <Card className="border-0 bg-white/90">
                <CardHeader className="border-b bg-gradient-to-r from-emerald-400/80 to-teal-400/80 text-white rounded-t-lg">
                  <CardTitle className="text-lg font-medium">Appuntamenti Prenotati</CardTitle>
                </CardHeader>
                <CardContent className="p-0">
                  {appointments.length > 0 ? (
                    <ul className="divide-y divide-emerald-100">
                      {appointments.map((appointment, index) => (
                        <li key={index} className="p-4 flex justify-between items-center hover:bg-emerald-50/50 transition-colors">
                          <div className="flex items-center gap-4">
                            <div className="text-emerald-700">
                              <p className="font-medium">
                                {new Date(appointment.date).toLocaleDateString('it-IT')}
                              </p>
                              <p className="text-sm text-emerald-600">
                                {appointment.time}
                              </p>
                            </div>
                          </div>
                          <span
                            className={`text-sm px-3 py-1 rounded-full ${appointment.status === 'scheduled'
                              ? 'bg-emerald-100 text-emerald-700'
                              : 'bg-red-100 text-red-700'
                              }`}
                          >
                            {appointment.status}
                          </span>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className="p-4 text-emerald-700">Non hai appuntamenti prenotati.</p>
                  )}
                </CardContent>
              </Card>
            </div>
          )}

          {/* {isFirstInvoicePaid && (
            <Card className="border-0 bg-white/90 mt-6">
              <CardHeader className="border-b bg-gradient-to-r from-emerald-400/80 to-teal-400/80 text-white rounded-t-lg">
                <CardTitle className="text-lg font-medium flex items-center gap-2">
                  <MessageCircle className="w-5 h-5" />
                  Chat con {therapist?.name} {therapist?.surname}
                </CardTitle>
              </CardHeader>
              <CardContent className="p-0">
                <div className="h-[400px] overflow-y-auto p-4 space-y-4">
                  {messages.map((msg, i) => (
                    <ChatMessage
                      key={i}
                      message={msg}
                      isOwnMessage={msg.senderId === profile?.id}
                      isTherapistMessage={msg.senderType === 'therapist'}
                    />
                  ))}
                  <div ref={chatEndRef} />
                </div>

                <div className="p-4 border-t">
                  <form onSubmit={sendMessage} className="flex gap-2">
                    <input
                      type="text"
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      placeholder="Scrivi un messaggio..."
                      className="flex-1 px-4 py-2 rounded-lg bg-white border-0 focus:ring-2 focus:ring-emerald-500 placeholder-gray-500"
                    />
                    <Button type="submit" className="bg-emerald-600 hover:bg-emerald-700">
                      <Send className="w-5 h-5" />
                    </Button>
                  </form>
                </div>
              </CardContent>
            </Card>
          )} */}
           <Card className="border-0 bg-white/90 mt-6">
              <CardHeader className="border-b bg-gradient-to-r from-emerald-400/80 to-teal-400/80 text-white rounded-t-lg">
                <CardTitle className="text-lg font-medium flex items-center gap-2">
                  <MessageCircle className="w-5 h-5" />
                  Chat con {therapist?.name} {therapist?.surname}
                </CardTitle>
              </CardHeader>
              <CardContent className="p-0">
                {renderChat()}
              </CardContent>
            </Card>
        </div>
      </div>
    </div>


  );
};

export default PatientDashboard;