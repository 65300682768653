import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PanelsTopLeft, Calendar, FileText, User, Users } from 'lucide-react';

const Sidebar = () => {
  const location = useLocation();
  
  const isActive = (path) => {
    return location.pathname === path;
  };

  const NavLink = ({ to, icon: Icon, children }) => {
    const active = isActive(to);
    
    return (
      <Link
        to={to}
        className={`
          flex items-center space-x-3 px-4 py-3 rounded-lg transition-all duration-200
          ${active ? 
            'bg-gradient-to-r from-emerald-50 to-teal-50 text-emerald-700 border-r-4 border-emerald-500' : 
            'text-gray-600 hover:bg-gradient-to-r hover:from-emerald-50/50 hover:to-teal-50/50 hover:text-emerald-600'
          }
        `}
      >
        <Icon className={`w-5 h-5 ${active ? 'text-emerald-600' : 'text-gray-500'}`} />
        <span className={`font-medium ${active ? 'text-emerald-700' : 'text-gray-600'}`}>
          {children}
        </span>
      </Link>
    );
  };

  return (
    <div className="w-[280px] h-screen bg-white border-r border-gray-100 flex flex-col fixed">
      <div className="p-6 border-b border-gray-100">
        <img 
          src="/logo.png" 
          alt="Etherapy" 
          className="h-[100px] object-contain transition-all duration-200 hover:opacity-90" 
        />
      </div>
      
      <nav className="flex-1 p-4 space-y-2">
        <NavLink to="/dashboard-patient" icon={PanelsTopLeft}>
          Dashboard
        </NavLink>
        
        <NavLink to="/sedute" icon={FileText}>
          Le tue fatture
        </NavLink>
        
        <NavLink to="/profilepage" icon={User}>
          Profilo
        </NavLink>
      </nav>

      <div className="p-4 border-t border-gray-100">
        <div className="p-4 rounded-lg bg-gradient-to-r from-emerald-50 to-teal-50">
          <h3 className="text-sm font-medium text-emerald-700 mb-2">
            Hai bisogno di aiuto?
          </h3>
          <p className="text-xs text-emerald-600">
            Contattaci via email o telefono per assistenza
          </p>
        </div>
      </div>

      <style jsx>{`
        @keyframes slideIn {
          from {
            transform: translateX(-10px);
            opacity: 0;
          }
          to {
            transform: translateX(0);
            opacity: 1;
          }
        }

        .animate-slide-in {
          animation: slideIn 0.2s ease-out;
        }
      `}</style>
    </div>
  );
};

export default Sidebar;