import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";

const TherapistLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/dashboard-therapist"); // Reindirizza alla dashboard del terapeuta
    } catch (err) {
      setError("Credenziali non valide. Riprova.");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <form onSubmit={handleLogin} className="flex flex-col max-w-md w-full p-8 bg-white shadow-lg rounded-lg">
        <div className="mb-8 min-w-full ">
          <img src="/logo.png" alt="Etherapy" className="h-[100px] ml-28" />
        </div>
        <h2 className="text-2xl font-bold mb-4 text-center text-emerald-700">Accesso Terapeuta</h2>
        {error && <p className="text-red-500 mb-2 text-center">{error}</p>}
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="mb-2 p-2 border rounded"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="mb-2 p-2 border rounded"
        />
        <button type="submit" className="bg-gradient-to-r from-emerald-500 to-teal-500 text-white py-2 rounded mt-4 hover:from-emerald-600 hover:to-teal-600 transition-all">
          Accedi come Terapeuta
        </button>
        <p className="text-sm text-center mt-4">
          Non hai un account? <a href="/therapistregister" className="text-blue-900">Registrati ora!</a>
        </p>
      </form>
    </div>
  );
};

export default TherapistLogin;