// src/pages/DashboardTherapist.jsx
import React from "react";
import TherapistDashboard from "../components/Therapist/TherapistDashboard";
import MatchList from "../components/Match/MatchList";

const DashboardTherapist = () => {
  return (
    <div className="flex">
      <TherapistDashboard />
    </div>
  );
};

export default DashboardTherapist;
