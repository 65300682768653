import React, { useState, useEffect } from 'react';
import { ref, onValue, update } from 'firebase/database';
import { auth, database } from '../../firebaseConfig';
import { findPotentialPatients, validateTherapistProfile, validatePatientData } from '../../utils/matching';
import { MessageCircle } from 'lucide-react';
import Sidebar from './Sidebar';
import ChatBox from './ChatBox'; // Importa il nuovo componente ChatBox
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';



const TherapistDashboard = () => {
  const [therapistProfile, setTherapistProfile] = useState(null);
  const [pendingPatients, setPendingPatients] = useState([]);
  const [acceptedPatients, setAcceptedPatients] = useState([]);
  const [debugInfo, setDebugInfo] = useState({
    therapistValidation: null,
    totalPatients: 0,
    filteredPatients: 0,
    matchingErrors: []
  });

  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if (!userId) {
      console.error('No user ID found');
      return;
    }

    const userRef = ref(database, `users/${userId}`);

    // Listener per il profilo del terapeuta
    const therapistListener = onValue(userRef, (snapshot) => {
      const therapistData = snapshot.val();

      // Validazione del profilo del terapeuta
      const therapistValidation = validateTherapistProfile(therapistData);
      if (!therapistValidation.isValid) {
        setDebugInfo(prev => ({
          ...prev,
          therapistValidation,
          matchingErrors: [...prev.matchingErrors, 'Profilo terapeuta non valido']
        }));
        return;
      }

      setTherapistProfile(therapistData);

      // Listener per i dati dei pazienti
      const patientsRef = ref(database, 'users');
      const patientsListener = onValue(patientsRef, (patientsSnapshot) => {
        const allUsers = patientsSnapshot.val();
        console.log('Dati dei pazienti:', allUsers);

        const patients = Object.entries(allUsers)
          .filter(([_, user]) => user.userType === 'patient')
          .map(([id, user]) => ({ ...user, id }));

        const invalidPatients = patients.filter(patient => {
          const validation = validatePatientData(patient);
          return !validation.isValid;
        });

        try {
          // Estrai l'array `matches` dall'oggetto restituito da `findPotentialPatients`
          let result = findPotentialPatients(patients, therapistData);
          let potentialMatches = Array.isArray(result.matches) ? result.matches : [];

          console.log('Pazienti potenzialmente abbinati:', potentialMatches);

          const pending = potentialMatches.filter(patient =>
            patient.matchStatus === 'pending' &&
            (!patient.matchedTherapistId || patient.matchedTherapistId === userId)
          );

          const accepted = potentialMatches.filter(patient =>
            patient.matchStatus === 'matched' && patient.matchedTherapistId === userId
          );

          setPendingPatients(pending);
          setAcceptedPatients(accepted);
          setDebugInfo({
            therapistValidation,
            totalPatients: patients.length,
            filteredPatients: potentialMatches.length,
            matchingErrors: invalidPatients.map(p => `Paziente non valido: ${p.id}`)
          });

        } catch (error) {
          setDebugInfo(prev => ({
            ...prev,
            matchingErrors: [...prev.matchingErrors, error.message]
          }));
        }

      });


      return () => {
        patientsListener();
      };
    });

    return () => {
      therapistListener();
    };
  }, []);

  const handlePatientResponse = async (patientId, accept) => {
    try {
      const patientRef = ref(database, `users/${patientId}`);
      const userId = auth.currentUser?.uid;

      await update(patientRef, {
        matchStatus: accept ? 'matched' : 'rejected',
        matchedTherapistId: accept ? userId : null
      });
    } catch (error) {
      console.error('Errore nell\'aggiornare lo stato del paziente:', error);
    }
  };



  return (
    <div className="flex min-h-screen min-w-full bg-white">
      <div className="w-[280px] fixed">
        <Sidebar />
      </div>
      <div className="flex-1 p-8 bg-gray-50 ml-52">
        <div className="max-w-5xl mx-auto">
          <header className="flex justify-between items-center mb-8">
            <h1 className="text-3xl font-serif text-emerald-800 font-semibold">Dashboard Terapeuta</h1>
          </header>

   
          <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
            <h2 className="text-xl font-serif mb-4 text-emerald-800 font-semibold">
              Pazienti in Attesa di Abbinamento ({pendingPatients.length})
            </h2>
            <div className="divide-y divide-gray-100">
              {pendingPatients.map(patient => (
                <div key={patient.id} className="py-4">
                  <p><strong>Nome:</strong> {patient.name}</p>
                  <button
                    onClick={() => handlePatientResponse(patient.id, true)}
                    className="bg-green-500 text-white rounded px-4 py-2 mr-2"
                  >
                    Accetta
                  </button>
                  <button
                    onClick={() => handlePatientResponse(patient.id, false)}
                    className="bg-red-500 text-white rounded px-4 py-2"
                  >
                    Rifiuta
                  </button>
                </div>
              ))}
            </div>
          </div>

          {/* Sezione Pazienti Accettati */}
          <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
            <h2 className="text-xl font-serif mb-4 text-emerald-800 font-semibold">
              Pazienti Accettati ({acceptedPatients.length})
            </h2>
            <div className="divide-y divide-gray-100">
              {acceptedPatients.map(patient => (
                <div key={patient.id} className="py-4">
                  <p className='text-emerald-800'><strong>Nome:</strong> {patient.name} {patient.surname}</p>
                </div>
              ))}
            </div>
          </div>

            <div className="h-[400px] overflow-y-auto mb-4">
          
              {acceptedPatients.map(patient => (
                <div key={patient.id} className="py-4">
                  <p className='text-emerald-800'><strong>Nome:</strong> {patient.name} {patient.surname}</p>
                  <ChatBox patientId={patient.id} />
                </div>
              ))}

            </div>
            
        </div>
      </div>
    </div>
  );
};

export default TherapistDashboard;