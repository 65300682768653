// matching.js
export function validateTherapistProfile(therapist) {
  if (!therapist) {
    console.error('No therapist profile provided');
    return { isValid: false, missingFields: ['entire profile'] };
  }

  const requiredFields = [
    'specialization',
    'gender',
    'availableTime',
    'ageRange',
    // 'workAreas'
  ];

  const missingFields = requiredFields.filter(field => !therapist[field]);
  
  // if (!Array.isArray(therapist.workAreas)) {
  //   missingFields.push('workAreas (not an array)');
  // }

  const validation = {
    isValid: missingFields.length === 0,
    missingFields,
    // hasValidWorkAreas: Array.isArray(therapist.workAreas) && therapist.workAreas.length > 0
  };

  console.log('Therapist validation result:', validation);
  return validation;
}

export function validatePatientData(patient) {
  if (!patient) {
    console.error('No patient data provided');
    return { isValid: false, missingFields: ['entire profile'] };
  }

  const requiredFields = [
    'therapyType',
    'preferredGender',
    'preferredTime',
    'therapistAgePreference',
    // 'reasons'
  ];

  const missingFields = requiredFields.filter(field => !patient[field]);
  
  // if (!Array.isArray(patient.reasons)) {
  //   missingFields.push('reasons (not an array)');
  // }

  const validation = {
    isValid: missingFields.length === 0,
    missingFields,
    // hasValidReasons: Array.isArray(patient.reasons) && patient.reasons.length > 0
  };

  console.log('Patient validation result:', validation);
  return validation;
}

export function findPotentialPatients(patients, therapistProfile) {
  // Input validation with detailed logging
  console.log('Starting matching process with:', {
    patientsCount: Array.isArray(patients) ? patients.length : 'invalid',
    therapistProfile: therapistProfile ? 'present' : 'missing'
  });

  if (!Array.isArray(patients)) {
    console.error('Patients must be an array');
    return {
      matches: [],
      error: 'invalid_patients_array'
    };
  }

  if (!therapistProfile) {
    console.error('No therapist profile provided');
    return {
      matches: [],
      error: 'missing_therapist_profile'
    };
  }

  const therapistValidation = validateTherapistProfile(therapistProfile);
  if (!therapistValidation.isValid) {
    console.error('Invalid therapist profile:', therapistValidation.missingFields);
    return {
      matches: [],
      error: 'invalid_therapist_profile',
      details: therapistValidation.missingFields
    };
  }

  // Normalize therapist data for comparison
  const normalizedTherapist = {
    specialization: therapistProfile.specialization.toLowerCase(),
    gender: therapistProfile.gender.toLowerCase(),
    availableTime: therapistProfile.availableTime.toLowerCase(),
    ageRange: therapistProfile.ageRange.toLowerCase(),
    // workAreas: therapistProfile.workAreas.map(area => area.toLowerCase())
  };

  console.log('Normalized therapist data:', normalizedTherapist);

  const matches = patients.filter(patient => {
    // Log each patient being evaluated
    console.log('Evaluating patient:', {
      id: patient.id,
      data: patient
    });

    // Validate patient data first
    const patientValidation = validatePatientData(patient);
    if (!patientValidation.isValid) {
      console.warn('Invalid patient data:', {
        id: patient.id,
        issues: patientValidation.missingFields
      });
      return false;
    }

    // Normalize patient data for comparison
    const normalizedPatient = {
      therapyType: patient.therapyType.toLowerCase(),
      preferredGender: patient.preferredGender.toLowerCase(),
      preferredTime: patient.preferredTime.toLowerCase(),
      therapistAgePreference: patient.therapistAgePreference.toLowerCase(),
      // reasons: patient.reasons.map(reason => reason.toLowerCase())
    };

    // Track matching criteria results
    const matchResults = {
      therapyType: false,
      gender: false,
      time: false,
      age: false,
      // workAreas: false
    };

    // Therapy type matching
    matchResults.therapyType = 
      normalizedPatient.therapyType === normalizedTherapist.specialization;

    // Gender preference matching
    matchResults.gender = 
      normalizedPatient.preferredGender === 'no preference' || 
      normalizedPatient.preferredGender === normalizedTherapist.gender;

    // Time preference matching
    matchResults.time = 
      normalizedPatient.preferredTime === 'no preference' || 
      normalizedPatient.preferredTime === normalizedTherapist.availableTime;

    // Age preference matching
    matchResults.age = 
      normalizedPatient.therapistAgePreference === 'no preference' || 
      normalizedPatient.therapistAgePreference === normalizedTherapist.ageRange;

    // Work areas matching - check if at least one reason matches
    // matchResults.workAreas = normalizedPatient.reasons.some(reason => 
    //   normalizedTherapist.workAreas.includes(reason)
    // );

    // Log detailed matching results for this patient
    console.log('Matching results for patient:', {
      id: patient.id,
      results: matchResults
    });

    // All criteria must match
    const isMatch = Object.values(matchResults).every(result => result === true);

    if (!isMatch) {
      console.log('Match failed for patient:', {
        id: patient.id,
        failedCriteria: Object.entries(matchResults)
          .filter(([_, passed]) => !passed)
          .map(([criteria]) => criteria)
      });
    }

    return isMatch;
  });

  // Log final matching results
  console.log('Matching process completed:', {
    totalPatients: patients.length,
    matchedPatients: matches.length
  });

  return {
    matches,
    stats: {
      total: patients.length,
      matched: matches.length
    }
  };
}

// Utility function to help with debugging
export function debugMatch(patient, therapist) {
  console.log('DEBUG: Attempting to match:', {
    patient: {
      therapyType: patient.therapyType,
      preferredGender: patient.preferredGender,
      preferredTime: patient.preferredTime,
      therapistAgePreference: patient.therapistAgePreference,
      // reasons: patient.reasons
    },
    therapist: {
      specialization: therapist.specialization,
      gender: therapist.gender,
      availableTime: therapist.availableTime,
      ageRange: therapist.ageRange,
      // workAreas: therapist.workAreas
    }
  });
}