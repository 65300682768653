import React, { useState } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { ref, set } from "firebase/database";
import { auth, database } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";

const TherapistRegister = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();

    // Verifica l'accettazione dei termini e condizioni
    if (!termsAccepted) {
      setError("Devi accettare i termini e condizioni per procedere.");
      return;
    }

    try {
      // Creazione utente Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user.uid;

      // Salvataggio dati utente nel Realtime Database
      await set(ref(database, `users/${userId}`), {
        name,
        surname,
        email,
        userType: 'therapist',
        userId,
      });

      // Reindirizza al form di completamento per terapeuti
      navigate('/therapist-form');
    } catch (err) {
      setError('Errore nella registrazione. Riprova.');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <form onSubmit={handleRegister} className="flex flex-col max-w-md w-full p-8 bg-white shadow-lg rounded-lg">
        <div className="mb-8 min-w-full ">
          <img src="/logo.png" alt="Etherapy" className="h-[100px] ml-28" />
        </div>
        <h2 className="text-2xl font-bold mb-4 text-center text-emerald-700">Registrazione Terapeuta</h2>
        {error && <p className="text-red-500 mb-2 text-center">{error}</p>}
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Nome"
          className="mb-2 p-2 border rounded"
        />
        <input
          type="text"
          value={surname}
          onChange={(e) => setSurname(e.target.value)}
          placeholder="Cognome"
          className="mb-2 p-2 border rounded"
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="mb-2 p-2 border rounded"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="mb-2 p-2 border rounded"
        />
        <div className="mb-2 flex items-center">
          <input
            type="checkbox"
            checked={termsAccepted}
            onChange={(e) => setTermsAccepted(e.target.checked)}
            className="mr-2"
          />
          <label>
            Accetto i <a href="/terms" className="text-blue-900">termini e condizioni</a>
          </label>
        </div>
        <button type="submit" className="bg-gradient-to-r from-emerald-500 to-teal-500 text-white py-2 rounded mt-4 hover:from-emerald-600 hover:to-teal-600 transition-all">
          Registrati come Terapeuta
        </button>
        <p className="text-sm text-center mt-4">
          Hai già un account? <a href="/therapistlogin" className="text-blue-900">Accedi ora!</a>
        </p>
      </form>
    </div>
  );
};

export default TherapistRegister;