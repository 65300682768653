import * as React from "react"
import { ChevronLeft, ChevronRight } from "lucide-react"
import { Button } from "./button"

const Calendar = React.forwardRef(({
  mode = "single",
  selected,
  onSelect,
  disabled,
  className,
  ...props
}, ref) => {
  const [currentMonth, setCurrentMonth] = React.useState(new Date())

  const getDaysInMonth = (date) => {
    const year = date.getFullYear()
    const month = date.getMonth()
    const days = new Date(year, month + 1, 0).getDate()
    const firstDay = new Date(year, month, 1).getDay()
    return { days, firstDay }
  }

  const { days, firstDay } = getDaysInMonth(currentMonth)

  const isDateDisabled = (date) => {
    if (!disabled) return false
    if (disabled.before && date < disabled.before) return true
    if (disabled.after && date > disabled.after) return true
    return false
  }

  const handleDateSelect = (date) => {
    if (isDateDisabled(date)) return
    onSelect?.(date)
  }

  const renderDays = () => {
    const daysArray = []
    for (let i = 0; i < firstDay; i++) {
      daysArray.push(<div key={`empty-${i}`} className="p-2" />)
    }
    
    for (let day = 1; day <= days; day++) {
      const date = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day)
      const isSelected = selected?.toDateString() === date.toDateString()
      const isDisabled = isDateDisabled(date)
      
      daysArray.push(
        <button
          key={day}
          onClick={() => handleDateSelect(date)}
          disabled={isDisabled}
          className={`p-2 w-full rounded-md ${
            isSelected 
              ? 'bg-coral-500 text-white' 
              : isDisabled 
                ? 'text-gray-300 cursor-not-allowed'
                : 'hover:bg-gray-100'
          }`}
        >
          {day}
        </button>
      )
    }
    return daysArray
  }

  return (
    <div ref={ref} className={`w-full ${className}`} {...props}>
      <div className="flex items-center justify-between mb-4">
        <Button
          variant="outline"
          onClick={() => setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1))}
        >
          <ChevronLeft className="h-4 w-4" />
        </Button>
        <div className="font-semibold">
          {currentMonth.toLocaleDateString('it-IT', { month: 'long', year: 'numeric' })}
        </div>
        <Button
          variant="outline"
          onClick={() => setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1))}
        >
          <ChevronRight className="h-4 w-4" />
        </Button>
      </div>
      <div className="grid grid-cols-7 gap-1 text-center text-sm mb-2">
        {['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'].map((day) => (
          <div key={day} className="font-medium text-gray-600">
            {day}
          </div>
        ))}
      </div>
      <div className="grid grid-cols-7 gap-1">
        {renderDays()}
      </div>
    </div>
  )
})
Calendar.displayName = "Calendar"

export { Calendar }