// src/pages/DashboardPatient.jsx
import React from "react";
import PatientDashboard from "../components/Patient/PatientDashboard";
import MatchList from "../components/Match/MatchList";

const DashboardPatient = () => {
  return (
    <div className="flex">
      <PatientDashboard />
    </div>
  );
};

export default DashboardPatient;
