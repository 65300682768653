import React, { useState } from 'react';
import { X, Send, BotMessageSquare } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
import { Button } from '../ui/button';

const ChatbotComponent = () => {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSendMessage = async () => {
    if (!userInput.trim()) return;

    try {
      setIsLoading(true);
      setMessages((prev) => [...prev, { role: 'user', content: userInput }]);
      
      const response = await fetch('https://e-therapy-back-end.vercel.app/api/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ texts: [userInput] }),
      });
      
      const data = await response.json();
      const chatbotResponse = data.response.output;

      setMessages((prev) => [
        ...prev,
        { role: 'chatbot', content: chatbotResponse || 'Nessuna risposta disponibile.' }
      ]);

      setUserInput('');
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages((prev) => [
        ...prev,
        { role: 'chatbot', content: 'Si è verificato un errore durante la risposta.' }
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleChatbot = () => {
    setIsOpen(!isOpen);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  return (
    <div className="fixed top-4 right-4 z-50">
      <Button
        onClick={toggleChatbot}
        className="flex items-center gap-2 bg-gradient-to-br from-emerald-500 to-teal-700/20 backdrop-blur-sm text-white rounded-lg shadow-lg transition-all duration-300"
      >
        {isOpen ? (
          <X className="w-5 h-5" />
        ) : (
          <>
            <BotMessageSquare className="w-5 h-5" />
            <span>AIssistenza</span>
          </>
        )}
      </Button>

      {isOpen && (
        <div className="fixed inset-0  flex items-center justify-center animate-fade-in">
          <Card className="w-96 max-w-full m-4 animate-slide-up glass-effect">
            <CardHeader className="border-b border-white/10 bg-gradient-to-r from-emerald-600/80 to-teal-600/80 text-white rounded-t-lg">
              <div className="flex justify-between items-center">
                <CardTitle className="text-lg font-medium flex gap-2"><BotMessageSquare className="w-5 h-5" />AIssistenza</CardTitle>
                <Button 
                  variant="ghost" 
                  size="icon"
                  onClick={toggleChatbot}
                  className="hover:bg-white/10 text-white rounded-full h-8 w-8 p-1"
                >
                  <X className="w-5 h-5" />
                </Button>
              </div>
            </CardHeader>

            <CardContent className="p-0 bg-white/10 backdrop-blur-md">
              <div className="h-[400px] overflow-y-auto p-4 space-y-4 custom-scrollbar">
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}
                  >
                    <div
                      className={`max-w-[70%] px-4 py-2 rounded-lg backdrop-blur-sm ${
                        message.role === 'user'
                          ? 'bg-white/90 border border-grey-500 text-right text-gray-800'
                          : 'bg-gradient-to-r text-left from-emerald-600/90 to-teal-600/90 text-white'
                      }`}
                    >
                      <p className="text-sm">{message.content}</p>
                      <span className="text-xs mt-1 block opacity-70">
                        {new Date().toLocaleTimeString('it-IT', { 
                          hour: '2-digit', 
                          minute: '2-digit' 
                        })}
                      </span>
                    </div>
                  </div>
                ))}
                {isLoading && (
                  <div className="flex justify-start">
                    <div className="bg-white/80 border border-grey-500 backdrop-blur-sm rounded-lg px-4 py-2">
                      <div className="flex space-x-2">
                        <div className="w-2 h-2 bg-emerald-500 rounded-full animate-bounce-delay-1"></div>
                        <div className="w-2 h-2 bg-emerald-500 rounded-full animate-bounce-delay-2"></div>
                        <div className="w-2 h-2 bg-emerald-500 rounded-full animate-bounce-delay-3"></div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="p-4 border-t border-white/10 bg-white/5 backdrop-blur-md">
                <div className="flex gap-2">
                  <input
                    type="text"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Scrivi un messaggio..."
                    className="flex-1 px-4 py-2 rounded-lg bg-white/80 backdrop-blur-sm border-0 focus:ring-2 focus:ring-emerald-500 placeholder-gray-500"
                  />
                  <Button
                    onClick={handleSendMessage}
                    disabled={isLoading}
                    className="bg-emerald-600 hover:bg-emerald-700"
                  >
                    <Send className="w-5 h-5" />
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      )}

      <style jsx>{`
        .glass-effect {
          background: rgba(255, 255, 255, 0.05);
          backdrop-filter: blur(10px);
          border: 1px solid rgba(255, 255, 255, 0.1);
          box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        }

        .custom-scrollbar::-webkit-scrollbar {
          width: 6px;
        }

        .custom-scrollbar::-webkit-scrollbar-track {
          background: rgba(255, 255, 255, 0.1);
        }

        .custom-scrollbar::-webkit-scrollbar-thumb {
          background: rgba(255, 255, 255, 0.2);
          border-radius: 3px;
        }

        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
          background: rgba(255, 255, 255, 0.3);
        }

        @keyframes fade-in {
          from { opacity: 0; }
          to { opacity: 1; }
        }

        @keyframes slide-up {
          from { transform: translateY(100px); opacity: 0; }
          to { transform: translateY(0); opacity: 1; }
        }

        @keyframes bounce-delay-1 {
          0%, 80%, 100% { transform: translateY(0); }
          40% { transform: translateY(-6px); }
        }

        @keyframes bounce-delay-2 {
          0%, 80%, 100% { transform: translateY(0); }
          50% { transform: translateY(-6px); }
        }

        @keyframes bounce-delay-3 {
          0%, 80%, 100% { transform: translateY(0); }
          60% { transform: translateY(-6px); }
        }

        .animate-fade-in {
          animation: fade-in 0.3s ease-out;
        }

        .animate-slide-up {
          animation: slide-up 0.3s ease-out;
        }

        .animate-bounce-delay-1 {
          animation: bounce-delay-1 1.4s infinite ease-in-out;
        }

        .animate-bounce-delay-2 {
          animation: bounce-delay-2 1.4s infinite ease-in-out;
        }

        .animate-bounce-delay-3 {
          animation: bounce-delay-3 1.4s infinite ease-in-out;
        }
      `}</style>
    </div>
  );
};

export default ChatbotComponent;