import React, { useState, useEffect } from "react";
import { ref, update } from "firebase/database";
import { auth, database } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";


const TherapistForm = () => {
  const [formData, setFormData] = useState({
    specialization: "",
    workAreas: [],
    specificIssues: [],
    gender: "",
    ageRange: "",
    availableTime: "",
  });
  
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};
    
    // Required field validation
    if (!formData.specialization) {
      newErrors.specialization = "La specializzazione è obbligatoria";
    }
    
    if (formData.workAreas.length === 0) {
      newErrors.workAreas = "Seleziona almeno un'area di lavoro";
    }
    
    if (!formData.gender) {
      newErrors.gender = "Il genere è obbligatorio";
    }
    
    if (!formData.ageRange) {
      newErrors.ageRange = "La fascia d'età è obbligatoria";
    }
    
    if (!formData.availableTime) {
      newErrors.availableTime = "L'orario preferito è obbligatorio";
    }

    // Specific validation for work areas that require specific issues
    if (formData.workAreas.includes("Disturbi emotivi e dell'umore") && 
        !formData.specificIssues.some(issue => 
          ["Disturbi dell'umore", "Disturbi d'ansia", "Disturbi alimentari", "Disturbo ossessivo-compulsivo"]
          .includes(issue))) {
      newErrors.emotionalIssues = "Seleziona almeno un disturbo specifico";
    }

    if (formData.workAreas.includes("Difficoltà relazionali") && 
        !formData.specificIssues.some(issue => 
          ["Difficoltà con il partner", "Difficoltà nelle amicizie", "Difficoltà nelle relazioni familiari", "Difficoltà nella sfera sessuale"]
          .includes(issue))) {
      newErrors.relationshipIssues = "Seleziona almeno una difficoltà specifica";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    // Clear error when field is modified
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: undefined }));
    }
  };

  const handleCheckboxChange = (area) => {
    const updatedWorkAreas = formData.workAreas.includes(area)
      ? formData.workAreas.filter((a) => a !== area)
      : [...formData.workAreas, area];
    setFormData(prev => ({ ...prev, workAreas: updatedWorkAreas }));
    
    // Clear work areas error when modified
    if (errors.workAreas) {
      setErrors(prev => ({ ...prev, workAreas: undefined }));
    }
  };

  const handleSpecificIssueChange = (issue) => {
    const updatedSpecificIssues = formData.specificIssues.includes(issue)
      ? formData.specificIssues.filter((i) => i !== issue)
      : [...formData.specificIssues, issue];
    setFormData(prev => ({ ...prev, specificIssues: updatedSpecificIssues }));
    
    // Clear specific issues errors when modified
    if (errors.emotionalIssues || errors.relationshipIssues) {
      setErrors(prev => ({ 
        ...prev, 
        emotionalIssues: undefined,
        relationshipIssues: undefined 
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    if (validateForm()) {
      try {
        const userId = auth.currentUser?.uid;
        if (!userId) throw new Error("Utente non autenticato");
        
        await update(ref(database, `users/${userId}`), {
          ...formData,
          profileComplete: true,
          lastUpdated: new Date().toISOString()
        });
        
        navigate("/dashboard-therapist");
      } catch (error) {
        setErrors(prev => ({
          ...prev,
          submit: "Errore durante il salvataggio. Riprova più tardi."
        }));
      }
    }
    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-md mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Completa il tuo profilo</h2>

      {errors.submit }

      {/* Specializzazione */}
      <div className="mb-4">
        <label className="block mb-2">
          In quale tipo di terapia sei specializzato? *
        </label>
        <select 
          name="specialization" 
          value={formData.specialization} 
          onChange={handleChange} 
          className={`p-2 border rounded w-full ${errors.specialization ? 'border-red-500' : ''}`}
        >
          <option value="">Seleziona il tipo di terapia</option>
          <option value="individual">Individuale</option>
          <option value="couple">Di coppia</option>
        </select>
        {errors.specialization && (
          <p className="text-red-500 text-sm mt-1">{errors.specialization}</p>
        )}
      </div>

      {/* Area di lavoro */}
      <div className="mb-4">
        <label className="block mb-2">
          In quali aree lavori? (seleziona tutto ciò che è applicabile) *
        </label>
        {["Disturbi emotivi e dell'umore", "Difficoltà relazionali", "Problemi lavorativi", "Esperienza di vita all'estero"].map((area, index) => (
          <div key={index} className="flex items-center mb-2">
            <input
              type="checkbox"
              checked={formData.workAreas.includes(area)}
              onChange={() => handleCheckboxChange(area)}
              className="mr-2"
            />
            <label>{area}</label>
          </div>
        ))}
        {errors.workAreas && (
          <p className="text-red-500 text-sm mt-1">{errors.workAreas}</p>
        )}
      </div>

      {/* Specifiche per Disturbi emotivi e dell'umore */}
      {formData.workAreas.includes("Disturbi emotivi e dell'umore") && (
        <div className="mb-4">
          <label className="block mt-4 mb-2">Disturbi emotivi e dell'umore - Specifica: *</label>
          {["Disturbi dell'umore", "Disturbi d'ansia", "Disturbi alimentari", "Disturbo ossessivo-compulsivo"].map((issue, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="checkbox"
                checked={formData.specificIssues.includes(issue)}
                onChange={() => handleSpecificIssueChange(issue)}
                className="mr-2"
              />
              <label>{issue}</label>
            </div>
          ))}
          {errors.emotionalIssues && (
            <p className="text-red-500 text-sm mt-1">{errors.emotionalIssues}</p>
          )}
        </div>
      )}

      {/* Specifiche per Difficoltà relazionali */}
      {formData.workAreas.includes("Difficoltà relazionali") && (
        <div className="mb-4">
          <label className="block mt-4 mb-2">Difficoltà relazionali - Specifica: *</label>
          {["Difficoltà con il partner", "Difficoltà nelle amicizie", "Difficoltà nelle relazioni familiari", "Difficoltà nella sfera sessuale"].map((issue, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="checkbox"
                checked={formData.specificIssues.includes(issue)}
                onChange={() => handleSpecificIssueChange(issue)}
                className="mr-2"
              />
              <label>{issue}</label>
            </div>
          ))}
          {errors.relationshipIssues && (
            <p className="text-red-500 text-sm mt-1">{errors.relationshipIssues}</p>
          )}
        </div>
      )}

      {/* Genere */}
      <div className="mb-4">
        <label className="block mb-2">Indica il tuo genere: *</label>
        <select 
          name="gender" 
          value={formData.gender} 
          onChange={handleChange} 
          className={`p-2 border rounded w-full ${errors.gender ? 'border-red-500' : ''}`}
        >
          <option value="">Seleziona il tuo genere</option>
          <option value="female">Donna</option>
          <option value="male">Uomo</option>
        </select>
        {errors.gender && (
          <p className="text-red-500 text-sm mt-1">{errors.gender}</p>
        )}
      </div>

      {/* Fascia d'età */}
      <div className="mb-4">
        <label className="block mb-2">Indica la tua fascia d'età: *</label>
        <select 
          name="ageRange" 
          value={formData.ageRange} 
          onChange={handleChange} 
          className={`p-2 border rounded w-full ${errors.ageRange ? 'border-red-500' : ''}`}
        >
          <option value="">Seleziona la tua fascia d'età</option>
          <option value="under35">Meno di 35 anni</option>
          <option value="over35">Più di 35 anni</option>
        </select>
        {errors.ageRange && (
          <p className="text-red-500 text-sm mt-1">{errors.ageRange}</p>
        )}
      </div>

      {/* Orario preferito per le sedute */}
      <div className="mb-4">
        <label className="block mb-2">Fascia oraria preferita per le sedute: *</label>
        <select 
          name="availableTime" 
          value={formData.availableTime} 
          onChange={handleChange} 
          className={`p-2 border rounded w-full ${errors.availableTime ? 'border-red-500' : ''}`}
        >
          <option value="">Seleziona la fascia oraria</option>
          <option value="morning">Mattina</option>
          <option value="afternoon">Pomeriggio</option>
          <option value="evening">Sera</option>
          <option value="no preference">Non ho preferenze</option>
        </select>
        {errors.availableTime && (
          <p className="text-red-500 text-sm mt-1">{errors.availableTime}</p>
        )}
      </div>

      <button 
        type="submit" 
        className={`w-full bg-blue-500 text-white py-2 px-4 rounded ${isSubmitting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}
        disabled={isSubmitting}
      >
        {isSubmitting ? 'Salvataggio in corso...' : 'Salva'}
      </button>
    </form>
  );
};

export default TherapistForm;